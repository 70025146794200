import { Checkbox, FormControl, FormControlLabel, Grid, TextField, Paper, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import CustomSelect from '@survey/common/dist/components/form-controls/CustomSelect';
import { hasPermission } from '../auth/authOperations';
import { EDIT_SURVEY_TYPES } from '../auth/permissions';

const styles = () => ({
  formControl: {
    display: 'block',
    width: '100%',
    marginBottom: '1rem',
  },
  textField: {
    fontSize: 12,
  },
});

const scoringOptions = [
  { label: 'None', value: 'None' },
  { label: 'O-EMRAM', value: 'O-EMRAM' },
  { label: 'EMRAM', value: 'EMRAM' },
  { label: 'AMAM', value: 'AMAM' },
  { label: 'INFRAM', value: 'INFRAM' },
  { label: 'CISOM', value: 'CISOM' },
  { label: 'DHI', value: 'DHI' },
  { label: 'DHI Short', value: 'DHIShort' },
  { label: 'GDHP', value: 'GDHP' },
  { label: 'CCMM', value: 'CCMM' },
  { label: 'C-COMM', value: 'C-COMM' },
  { label: 'INFRAM 2024', value: 'INFRAM 2024' },
];
const imageOptions = [{ label: 'Mobile Study', value: 'https://s3.amazonaws.com/survey-external-dev/static/media/Untitled-1.png' }];

const ThankYouForm = ({ thankyouMessage, scoringType, onChange, onSelectChange, classes, permissions, widgets, isExternalSurvey }) => {
  const widgetOne = widgets.length > 0 ? find(imageOptions, { value: widgets[0].media }) : '';
  const widgetTwo = widgets.length > 1 ? find(imageOptions, { value: widgets[1].media }) : '';

  return (
    <Grid container spacing={10}>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl}>
          <TextField
            className={classes.textField}
            disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)}
            fullWidth
            key="Thank You Name"
            label="Thank You Message"
            margin="dense"
            multiline
            name="thankyouMessage"
            onChange={(event) => onChange(event)}
            value={thankyouMessage}
          />
        </FormControl>
      </Grid>
      {isExternalSurvey && (
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl}>
            <CustomSelect disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)} label="Scoring Type" name="scoringType" onChange={onSelectChange} options={scoringOptions} value={scoringType} />
          </FormControl>
          <FormControl className={classes.formControl} style={{ marginBottom: 0 }}>
            <FormControlLabel
              disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)}
              control={<Checkbox checked={widgets.length > 0} onChange={(event) => onChange(event)} name="widget-one" color="primary" />}
              label="Widget One"
            />
          </FormControl>
          {widgets.length > 0 && (
            <Fragment>
              <FormControl className={classes.formControl}>
                {/* <CustomSelect disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)} label="Widget Image" name="image-one" onChange={onSelectChange} options={imageOptions} value={widgetOne} /> */}
                <TextField disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)} label="Widget Image" name="image-one" onChange={(event) => onChange(event)} fullWidth value={widgets[0].image} />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)}
                  label="Widget Content"
                  name="content-one"
                  multiline={true}
                  onChange={(event) => onChange(event)}
                  fullWidth
                  value={widgets[0].content}
                />
              </FormControl>
              <Paper>
                <Typography variant="subtitle1" component="div">
                  <div dangerouslySetInnerHTML={{ __html: widgets[0].content }} />
                </Typography>
              </Paper>
            </Fragment>
          )}
          <FormControl className={classes.formControl} style={{ marginBottom: 0 }}>
            <FormControlLabel
              disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions) || widgets.length < 1 || (widgets.length === 1 && scoringType.value !== 'None')}
              control={<Checkbox checked={widgets.length > 1} onChange={(event) => onChange(event)} name="widget-two" color="primary" />}
              label="Widget Two"
            />
          </FormControl>
          {widgets.length > 1 && (
            <Fragment>
              <FormControl className={classes.formControl}>
                <TextField disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)} label="Widget Image" name="image-two" onChange={(event) => onChange(event)} fullWidth value={widgets[1].image} />
                {/* <CustomSelect disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)} label="Widget Image" name="image-two" onChange={onSelectChange} options={imageOptions} value={widgetTwo} /> */}
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)}
                  label="Widget Content"
                  name="content-two"
                  multiline={true}
                  onChange={(event) => onChange(event)}
                  fullWidth
                  value={widgets[1].content}
                />
              </FormControl>
              <Paper>
                <Typography variant="subtitle1" component="div">
                  <div dangerouslySetInnerHTML={{ __html: widgets[1].content }} />
                </Typography>
              </Paper>
            </Fragment>
          )}
        </Grid>
      )}
    </Grid>
  );
};

ThankYouForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired,
  scoringType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  thankyouMessage: PropTypes.string.isRequired,
  widgets: PropTypes.array.isRequired,
};

export default withStyles(styles)(ThankYouForm);
